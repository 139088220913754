<!-- Base de Cabecera del Finder -->

<template>
  <div :class="Entorno.class" :style="Entorno.style">
    <!-- Título -->
    <span>{{ Entorno.titulo }}</span>

    <!-- Botón para cerrar la ventana modal -->
    <v-btn v-if="Entorno.closable" v-bind="$cfg.btn.cerrar" @click="cerrar_form()">
      <v-icon>{{ $cfg.btn.cerrar.icono }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {    
    padre: { type:String, default:'' },
    Entorno: { type: Object, default: () => undefined }
  },

  methods: {
    
    cerrar_form() {
      this.$emit("onEvent", { accion:'cerrar' });      
    }
  }
};
</script>
